'use strict'


const Queue = ([...vals] = [], efn) => {
    let [psh, pll, sz] = [[], vals.reverse(), vals.length],
        push = !!efn
            ? (v) => (psh.push(v), efn.push(), ++sz)
            : (v) => (psh.push(v), ++sz),
        grab = !!efn
            ? () => (pll = psh.reverse(), psh = [], apply(pll.pop(), efn.push) )
            : () => (pll = psh.reverse(), psh = [], pll.pop() ),
        pull = !!efn
            ? ( ) => (sz !== 0 ? ( --sz, apply(pll.pop() ?? grab(), efn.pull) ) : null)
            : ( ) => (sz !== 0 ? ( --sz, pll.pop() ?? grab() ) : null);
    vals = null;
    return props((
        (v) => defined(v) ? push(v) : pull()
    ), {
        size:   () => sz,
        push:   push,
        pull:   pull,
        copy:   ( ) => [...psh, ...pll.reverse()],
        dump:   ( ) => {
            let v = [];
            psh.length && (v = v.concat(psh));
            pll.length && (v = v.concat(pll.reverse()));
            psh = []; pll = []; sz = 0;
            return v;
        },
        drop:   ([...vals]) => {
            psh = psh.concat(vals);
            sz = psh.length + pll.length;
        },
        json:   props(() => jsn.string([...psh, ...pll.reverse()]), {
            push: () => jsn.string(psh),
            pull: () => jsn.string(pll)
        }),
        clear:  ( ) => (psh = [], pll = [], sz = 0),
        toJSON: ( ) => [...psh, ...pll.reverse()]
    });
};
const Stack = ([...vals] = [], efn) => {
    let lst = [...vals],
        push = !!efn
            ? (v) => (lst.push(v), efn(), lst.length)
            : (v) => (lst.push(v), lst.length),
        pull = !!efn
            ? () => ( lst.length !== 0 ? apply(lst.pop(), efn) : null )
            : () => ( lst.length !== 0 ? lst.pop() : null );
    vals = null;
    return props((
        (v) => defined(v)
            ? push(v)
            : pull()
    ), {
        size:   () => lst.length,
        push:   push,
        pull:   pull,
        copy:   ( ) => [...lst],
        json:   ( ) => jsn.string(lst),
        toJSON: ( ) => [...lst]
    });
};
const Frame = _((
    getPath  = (o, str) => str.includes('.')
        ? str.split('.').reduce(
            (op, v) => (isMap(op) && op.has(v) ? op.get(v) : null), o
        )
        : o.get(str) ?? null,
    typeCase = casing({
        // Assign
        Object:   (o, obj, efn) => (
            entries(obj).forEach(
                ([k, v]) => defined(v)
                    ? o.set(k, v)
                    : o.delete(k)
            ), _(efn), o
        ),
        Null:     (o, nl, efn) => (
            o.clear(), _(efn), o
        ),
        // Fetch
        Function: (o, fnc) => fnc(fromEntries(Array.from(o))),
        String:   getPath,
        Array:    (o, arr) => arr.map( (str) => getPath(o, str) )
    }, (o, v) => (o) )
) => (
    (obj = new Map(), efn) => props(
        (v) => typeCase( proto(v) )( obj, v, efn ), {
            set:  (k, v) => void (obj.set(k, v), _(efn)),
            del:  (k) => void (obj.delete(k), _(efn)),
            get:  (k) => getPath(obj, k),
            has:  (k) => obj.has(k),
            copy: () => ([...obj]),
            json: () => jsn.string([...obj]),
            size: () => keys(obj).length
        }
    )
));
const Mapper = _((
    api = ({
        toJSON: function toJSON() {
            return {...this};
        }
    })
) => (data = {}, efn) => {
    let o = {}, $ = Frame(o), dtm = new Proxy(
        attach(o, { $, ...api }),
        {
            get: (obj, prop) => {
                if (!(prop in obj)) {
                    obj[prop] = ({});
                    _(efn);
                }
                return obj[prop];
            },
            set: (obj, prop, value) => {
                if (isObject(value)) {
                    obj[prop] = Mapper(value);
                } else {
                    obj[prop] = value;
                }
                _(efn);
                return value;
            }
        }
    );
    !empty(data) && assign(dtm, data);
    return dtm;
});
const State = (fn) => _(
    (O = Mapper(), { $ } = O) => fn({ O, $ })
);
const Value = vfn;


module.exports = ({ Value, Queue, Stack, Frame, Mapper, State });
