'use strict';


/**
 * Data structure and rule validation engine
 * @module Skema
*/

const format = require('./format')
const validate = require('./validate').validate

const skema = ({ format, validate });

module.exports = ({ skema });
