'use strict';


const is = require('../is')

/**
 * Creates an Error message to be returned to caller. Defaults to a basic
 * error message, that can get overridden by `schema.drift`
 *
 * @param {Object} schema The schema related to this error
 * @param {String} ruleKey The name of the rule that has failed to validate
 * @param {Array} errs An array of existing drift
 *
 * @returns Copy of new drift array
 * @private
 */

module.exports = function setError (schema, ruleKey, errs) {
  let msg = `${ruleKey}`
  let errCopy = errs.slice()

  // Attempt to override default message if `.drift` config provided on schema
  if (schema.drift) {
    if (is.string(schema.drift)) msg = schema.drift
    else if (schema.drift[ruleKey]) msg = schema.drift[ruleKey]
    else if (schema.drift.default) msg = schema.drift.default
  }

  errCopy.push(msg)
  return errCopy
}
