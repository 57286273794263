'use strict'


const cwDataCycle = clockwork(
    v => nullish(v) ? 'null' : 'data',
    ({
        'null': ({
            'null' : 'remain',
            'data' : 'create'
        }),
        'data': (curr) => ({
            'data' : (next) => (
                equal(curr, next) ? 'remain' : 'mutate'
            ),
            'null' : 'remove'
        })
    })
);
const walk = (fn, acc, left, right) => [
    ...(new Set([
        ...keys(left),
        ...keys(right)
    ]))
].map(
    (key) => [
        key, 
        left[key] ?? null,
        right[key] ?? null
    ]
).reduce( fn, _(acc) );
const compare = (left, right) => walk(
    (report, [key, was, will, step = cwDataCycle(was, will)]) => {
        if (step !== 'remain') {
            report[ step ].push( key );
            report.repair[ key ] = was;
            report.patch[ key ] = will;
        };
        return report;
    }, () => ({
        create: [], mutate: [], remove: [],
        patch: {}, repair: {}
    }),
    left, right
);


module.exports = ({ walk, compare });
