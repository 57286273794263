'use strict';

import "./styles.scss";
import "./lib/sorcery";

import Cursor from './lib/cursor';
env({ curses: new Cursor() });
env({ ...require('./shape/tag') });
env({ ...require('./shape/grid') });



env({ concept: build({
    form: (schema, exec) => {
        const names = keys(schema);
        const data  = fromEntries(
            names.map(
                name => [name, vfn(schema[name].data ?? '')]
            )
        );
        const snap  = () => fromEntries(names.map(
            (name) => [name, data[name]()]
        ))
        const check = () => skema.validate(
            schema, snap()
        );
        return build({
            mode:  vfn('led-calm'),
            skema: fromEntries(names.map(
                (title, idx) => [title, config({
                    title, value: data[title],
                    input: schema[title].input ?? 'text',
                    oneOf: schema[title].rules.oneOf ?? [],
                    imask: schema[title].mask ?? false,
                    check, focus: !idx
                })]
            )),
            valid: () => check().valid,
            pulse: () => exec( snap() )
        });
    },
    field: ({attrs, state}) => {
        const field = attrs.config ?? {};
        const name  = field.title ?? '';
        state.conf  = style('field');
        state.field = stack({
            title: name,
            intag: field.input === 'textarea' ? 'textarea' : 'input',
            input: field.input ?? 'text',
            named: field.named ?? `form-input-${sid()}`,
            value: field.value ?? vfn(''),
            drift: field.drift ?? vfn(false),
            oneOf: field.oneOf ?? [],
            imask: field.imask ?? false,
            focus: !!field.focus
        }, defined(field.check) && ({
            allow: field.allow ?? vfn(true),
            check: field.check
        }));
        if (defined(state.field.check)) {
            let empty, drift, valid;
            state.mode = state.field.value.feed(
                () => {
                    drift = state.field.check().drift ?? {},
                    valid = !(name in drift);
                    state.field.drift(
                        !valid && !empty ? drift[name][0] : false
                    );
                    return valid ? 'green' : 'orange glow';
                }
            );
            state.stone = () => (empty = !(state.field.value() ?? '').length);
            state.stone();
        } else {
            state.mode = vfn('off');
            state.stone = fnVoid;
        }
    }
}) });

env({ paper: ({
    viewport: ({
        oninit: ({state}) => (state.conf = style('viewport')),
        view: ({
            attrs, state: {conf}, children
        }) => div(stack(conf, attrs), children)
    }),
    surface: ({
        oninit: ({state}) => (state.conf = style('surface')),
        view: ({
            attrs, state: {conf}, children
        }) => div(stack(conf, attrs), children)
    }),
    section: ({
        oninit: ({state}) => (state.conf = style('section')),
        view: ({
            attrs, state: {conf}, children
        }) => div(stack(conf, attrs), children)
    }),
    form: ({
        oninit: ({state}) => (state.conf = style('input')),
        view: ({
            attrs: {$}, state: {conf}, children
        }) => m('form.base[autocomplete="off"]', {
            id: sid(),
            class: $.mode(),
            onsubmit: (e) => (e.preventDefault(), false)
        }, [
            m('.input', conf, children),
            m('.control.loud', [
                m(paper.button, {
                    onmouseover: (e) => $.mode('led-glow'),
                    onmouseout:  (e) => $.mode('led-calm'),
                    onclick: (e) => {
                        e.preventDefault();
                        $.valid() && wait($.pulse, 0);
                        return false;
                    }
                }, [
                    m('span.led', {class: $.valid() ? 'green signal' : 'orange no-signal'}),
                    'Submit'
                ])
            ])
        ])
    }),
    field: ({
        oninit: ({attrs, state}) => {
            concept.field({attrs, state});
        },
        view: ({
            attrs, state: {conf, mode, field, stone}
        }) => m('div', stack({style: attrs.style ?? {}}, conf), [
            !field.title.length ? null : label({
                for: `${field.named}`
            }, [
                m('span.led', {class: mode()}), field.title
            ]),
            field.intag !== 'input' ? null : m('input', {
                id: field.named,
                type: field.input,
                oncreate: ({dom}) => {
                    curses.initText(dom);
                    field.imask && masker(dom, field.imask, field.value);
                    field.focus && wait(() => dom.focus(), 100);
                },
                oninput:  field.imask ? fnVoid : ({target: {value}}) => field.value(value),
                name:     field.named,
                value:    field.value(),
                onblur:   stone,
                disabled: !!attrs.disabled
            }),
            field.intag !== 'textarea' ? null : m('textarea', {
                id: field.named,
                rows: 5,
                oncreate: ({dom}) => {
                    curses.initText(dom);
                    field.focus && wait(() => dom.focus(), 100);
                },
                oninput:  field.imask ? fnVoid : ({target: {value}}) => field.value(value),
                name:     field.named,
                onblur:   stone,
                disabled: !!attrs.disabled
            }, field.value()),
            !field.drift() ? null : m('span.drift', field.drift())
        ])
    }),
    choose: ({
        oninit: ({attrs, state}) => {
            concept.field({attrs, state});
            state.opts = style('choice');
        },
        view: ({
            attrs, state: {conf, opts, mode, field}
        }) => m('div', conf, [
            !field.title.length ? null : label([
                m('span.led', {class: mode()}), field.title
            ]),
            m('div', opts, field.oneOf.map(
                (title) => m('span', {
                    class: field.value() === title ? 'chosen' : '',
                    onclick: () => field.value(title)
                }, [title])
            )),
            !field.drift() ? null : m('span.drift', field.drift())
        ])
    }),
    button: ({
        oninit: ({state}) => (state.conf = style({ float: 'right' })),
        view: ({
            attrs, state: {conf}, children
        }) => m('button', stack(conf, attrs, {
            oncreate: ({dom}) => curses.initLink(dom)
        }), children)
    }),
    line: ({
        oninit: ({state}) => (state.conf = style('line')),
        view: ({
            attrs, state: {conf}, children
        }) => div(stack(conf, attrs), children)
    }),
    title: ({
        oninit: ({state}) => (state.conf = style('primary', {
            padding: '0 24px'
        })),
        view: ({
            attrs, state: {conf}, children
        }) => m('h2', stack(conf, attrs), children)
    }),
    item: ({
        oninit: ({state}) => (state.conf = style('item')),
        view: ({
            attrs, state: {conf}, children
        }) => div(stack(conf, attrs), children)
    })
}) });

let touch = [];
let dark  = window.matchMedia(
    '(prefers-color-scheme: dark)'
).matches;
let mode  = 'test';

//let search = require('./page/profile-search')(Root);
m.route.prefix = '';
let router = (dom) => {
    m.route(dom, "/", {
        '/':                { onmatch: () => import('./page/home') },
        '/home':            { onmatch: () => import('./page/home') },
        '/recruit':         { onmatch: () => import('./page/recruit') },
        '/login':           { onmatch: () => import('./page/login') },
        '/register':        { onmatch: () => import('./page/register') },
        '/reset-password':  { onmatch: () => import('./page/reset-password') },
        '/find-me':         { onmatch: () => import('./page/find-me') },
        '/how-this-works':  { onmatch: () => import('./page/how-this-works') },
        '/contact-us':      { onmatch: () => import('./page/contact-us') }
    });    
};

const contextStyle = () => document.body.className = [
    'dark-theme',   //dark ? 'dark-theme' : 'light-theme',
    media.isMobile
        ? 'mobile-size'
        : 'web-size',
    `env-${mode}`
].join(' ');
contextStyle();

m.mount(document.body, {
    view: () => m('div.stacking[curses][fit][contain]', {
        oncreate: () => next(() => curses.init())
    }, [
        m('[cursing][point]', {}, []),
        m('[cursing][hollow]', {}, []),
        m('div[fit][contain][layer][depth-10000][pointless]', {
            //oncreate: ({dom}) => next(() => Root.ripple.init(dom))
        }, []),
        m('.main-layout[fit][contain][layer][depth-0]', {
            /*oncreate: ({dom}) => {
                touch.push(dom);
                dom.onmousedown = (e) => {
                    Root.ripple.at({x: e.clientX, y: e.clientY});
                    touch.includes(e.target) && wait(
                        () => Root.ripple.at({x: e.clientX, y: e.clientY}), 100
                    )
                }
            }*/
        }, [
            header({
                class: 'logo',
                onmousedown: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                },
                ondblclick: () => {
                    mode = mode === 'test' ? 'live' : 'test';
                    next( contextStyle );
                },
                oncontextmenu: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    dark = !dark;
                    next( contextStyle );
                }
            }, [
                image('QuiQkr-Logo')
            ]),
            header({
                oncreate: ({dom}) => touch.push(dom)
            }, [
                m('a.button', {
                    href: '/recruit',
                    style: {
                        position: 'absolute',
                        top: '18px',
                        left: '126px'
                    },
                    onclick: function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        m.route.set(this.href);
                    },
                    //onclick: () => m.route.set('/article/:find', {find: 'stuff'}),
                    oncreate: ({dom}) => curses.initLink(dom)
                }, [ t4('Search Recruits') ]),
                hList( style({
                    position: 'absolute',
                    left: '120px',
                    bottom: '0',
                    gap: '24px',
                    justifyContent: 'flex-start'
                }), [
                    'Home', 'Recruit', 'Find Me', 'How This Works', 'Contact Us'
                ].map((ttl) => link( {
                    oncreate: ({dom}) => curses.initNav(dom),
                    class: 'nav',
                    href: `/${ttl.toLowerCase().split(' ').join('-')}`,
                    style: { padding: '6px 0 8px' },
                    onclick: function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        m.route.set(this.href);
                    }
                }, t4(ttl) )))
            ]),
            header([
                hList(
                    style({
                        position: 'absolute',
                        top: '20px',
                        left: '4px',
                        right: '4px',
                        gap: '12px',
                        justifyContent: 'center',
                        color: 'var(--text-link)'
                    }),
                    (!sessionStorage.getItem('Token')
                        ? ['Login', '|', 'Register']
                        : ['Logout']
                    ).map((ttl) => ttl !== '|' 
                        ? link( {
                            oncreate: ({dom}) => curses.initNav(dom),
                            href: `/${ttl.toLowerCase().split(' ').join('-')}`,
                            class: 'access',
                            style: { padding: '2px 0' },
                            onclick: function(e) {
                                e.preventDefault();
                                e.stopPropagation();
                                ttl === 'Logout' && sessionStorage.removeItem('Token');
                                m.route.set(this.href);
                            }
                        }, t3(ttl) )
                        : t3(' | ')
                    )
                )
            ]),
            aside({class: 'present'}, []),
            main({
                oncreate: ({dom}) => router(dom)
            }, [
                h5('...loading...')
            ]),
            aside({class: 'context'}, []),
            footer([]),
            footer([
                hList( style({
                    position: 'absolute',
                    bottom: '14px',
                    left: '24px',
                    gap: '12px',
                    justifyContent: 'flex-start',
                    color: 'var(--text-link)'
                }), [
                    'Terms of Service', '|', 'Privacy Policy'
                ].map((ttl) => ttl !== '|' 
                    ? link( {
                        oncreate: ({dom}) => curses.initNav(dom),
                        href: `/${ttl.toLowerCase().split(' ').join('-')}`,
                        class: 'access',
                        style: { padding: '2px 0' },
                        onclick: function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            m.route.set(this.href);
                        }
                    }, t4(ttl) )
                    : t4(' | ')
                )),
                t4( style({
                    position: 'absolute',
                    bottom: '20px',
                    right: '24px',
                    gap: '12px',
                    justifyContent: 'flex-end'
                }), 'Copyright @ QuiQkr. All rights Reserved' )
            ]),
            footer([])
        ])
    ])
});