'use strict'


const os = require('os');
let dtm = ({ nxs: (time() / 1000 | 0) - 1, date: vfn(), time: vfn(), str: [], int: [] }),
    upd = (i, sfn) => ( sfn.echo(
        (v) => void (
            dtm.int[i] = v,
            dtm.str[i] = pad(v),
            i === 2 && dtm.time( dtm.str.join(':') )
        )
    ), sfn ),
    ymd = (d = new Date(dtm.nxs * 1000)) => dtm.date([
        d.getFullYear(),
        pad(d.getMonth() + 1),
        pad(d.getDate())
    ].join('-')),
    dte = new Date((dtm.nxs - 1) * 1000),
    hh  = upd(0, ifn([0, 23], ymd, dte.getHours() - 1) ),
    ii  = upd(1, ifn([0, 59], hh.tick,  dte.getMinutes() - 1) ),
    ss  = upd(2, ifn([0, 59], ii.tick,  dte.getSeconds()) ),
    tck = tick(() => ss.tick(dtm.nxs++), 1000, ((dtm.nxs + 2) * 1000) - 2, true);
[ymd, hh.tick, ii.tick, ss.tick].forEach(_);
const clock = props(() => dtm.nxs, {
    unix:     () => dtm.nxs,
    dateTime: () => `${dtm.date()} ${dtm.time()}`,
    date:     props(() => dtm.date(), {...dtm.date}),
    time:     props(() => dtm.time(), {...dtm.time}),
    hour:     props(() => dtm.int[0], {...hh}),
    minute:   props(() => dtm.int[1], {...ii}),
    second:   props(() => dtm.int[2], {...ss})
});
const cpus = os.cpus().length;
const cpuf = (100 / cpus) * 100;
let mon = ({
    loadAvg: vfn( os.loadavg().map(v => (cpuf * v | 0) / 100) ),
    freeMem: vfn( (os.freemem() / (1024 * 1024)) | 0 )
});
ss.count(5, () => next(() => (
    mon.loadAvg( os.loadavg().map(v => (cpuf * v | 0) / 100) ),
    mon.freeMem( (os.freemem() / (1024 * 1024)) | 0 )
)));
const sys = build({
    numCpus: cpus,
    loadAvg: props(() => mon.loadAvg(), {
        feed: mon.loadAvg.feed,
        echo: mon.loadAvg.echo
    }),
    freeMem: props(() => mon.freeMem(), {
        feed: mon.freeMem.feed,
        echo: mon.freeMem.echo
    })
});


module.exports = ({ clock, sys });
