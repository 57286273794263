'use strict';


const webSocket = (port = 8080) => {
    let uri    = port === 443
        ? 'wss://' + window.location.hostname
        : 'ws://' + window.location.hostname + (
            port === 80 ? '' : ':' + port
        ),  
        link   = null,
        plan   = null,
        queue  = Queue(),
        recon  = {};

    const isOpen  = () => link.readyState === WebSocket.OPEN;
    const consume = () => {
        if (!isOpen()) return;
        let i = 5;
        plan = null;
        while (i-- && queue.size()) {
            let data = queue.pull();
            link.send( data );
        }
        if (queue.size) {
            plan = sync.update( consume );
        }
    };
    const connect = () => {
        link = new WebSocket(uri);
        link.onopen = () => consume();
        link.onmessage = (msg) => {
            msg = jsn.parse(msg.data);
            if (msg.ping) {
                link.send( jsn.string({ pong: true }) );
                return;
            }
            if (recon[msg.reqId]) {
                recon[msg.reqId](msg);
                delete recon[msg.reqId];
            }
        };
        link.onerror = (error) => log('socket', { error });
        //link.onclose = () => wait(connect, 1000);
    };
    const send = (msg, cb) => {
        msg.reqId = sid();
        msg.Token = sessionStorage.getItem('Token');
        queue.push( jsn.string(msg) );
        if (isFunction(cb)) recon[msg.reqId] = cb;
        if (!plan) plan = sync.update( consume );
    };

    connect();
    return props(send, {
        isOpen, close: () => isOpen() && link.close()
    });
};

module.exports = ({ webSocket });
