'use strict';

import png from '../image/*.png';
import jpg from '../image/*.jpg';


let images = {};
entries(png).forEach(([name, url]) => (
    images[name] = ({url, type: 'image/png'})
));
entries(jpg).forEach(([name, url]) => (
    images[name] = ({url, type: 'image/jpeg'})
));

let option = casing({
    contain:      () => ({backgroundSize: 'contain'}),
    cover:        () => ({backgroundSize: 'cover'}),
    fixed:        () => ({backgroundAttachment: 'fixed'}),
    center:       () => ({backgroundPosition: 'center'}),
    topLeft:      () => ({backgroundPosition: 'top left'}),
    topCenter:    () => ({backgroundPosition: 'top center'}),
    topRight:     () => ({backgroundPosition: 'top right'}),
    centerRight:  () => ({backgroundPosition: 'center right'}),
    bottomRight:  () => ({backgroundPosition: 'bottom right'}),
    bottomCenter: () => ({backgroundPosition: 'bottom center'}),
    bottomLeft:   () => ({backgroundPosition: 'bottom left'}),
    centerLeft:   () => ({backgroundPosition: 'center left'})
}, () => ({}));

exports.bgImageStyle = (name, ...flags) => (
    natural = !flags.includes('contain') && !flags.includes('cover'),
    style(flags.map( option ), {
        backgroundImage:  `url('${images[name]}')`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'border-box'
    })
);
exports.image = (name, ...v) => m(`img[uid="${sid()}"][src="${images[name].url}"]`, ...v );
