'use strict'


const alpha  = 'ModuleSymbhasOwnPr00123456789ABCDEFGHNRVfgctiUvzZKqYTJkLxpZXIjQW';
const rbyte = (bts) => crypto.getRandomValues(new Uint8Array(bts));
const uuid = () => {
    let size = 18, bytes = rbyte(24), id = '';
    while (size--) id += alpha[bytes[size] & 63];
    return id;
}
const ssid = () => {
    let size = 12, bytes = rbyte(12), id = '';
    while (size--) id += alpha[bytes[size] & 63];
    return id;
}
const uid = () => {
    let size = 9, bytes = rbyte(9), id = '';
    while (size--) id += alpha[bytes[size] & 63];
    return id;
}
const sid = () => {
    let size = 6, bytes = rbyte(6), id = '';
    while (size--) id += alpha[bytes[size] & 63];
    return id;
}
const msid = (now = time(), id = sid()) => `${now}-${id}`;


module.exports = ({ uuid, ssid, msid, uid, sid });
