'use strict';

const invariant = (check, message) => {
    if (!check) {
        throw new Error(message);
    }
};

//-> ----------------------------------------- RENDER LOOP FROM FRAME-SYNC
let prevTime = 0;
prevTime = Date.now() - 5;
let onNextFrame = (callback) => window.requestAnimationFrame(callback);

let createStep = ((setRunNextFrame) => {
    let processToRun = [];
    let processToRunNextFrame = [];
    let numThisFrame = 0;
    let isProcessing = false;
    let i = 0;
    let cancelled = new WeakSet();
    let toKeepAlive = new WeakSet();
    let renderStep = {
        cancel: (process) => {
            let indexOfCallback = processToRunNextFrame.indexOf(process);
            cancelled.add(process);
            if (indexOfCallback !== -1) {
                processToRunNextFrame.splice(indexOfCallback, 1);
            }
        },
        process: (frame) => {
            let _a;
            isProcessing = true;
            _a = [
                processToRunNextFrame,
                processToRun
            ], processToRun = _a[0], processToRunNextFrame = _a[1];
            processToRunNextFrame.length = 0;
            numThisFrame = processToRun.length;
            if (numThisFrame) {
                let process_1;
                for (i = 0; i < numThisFrame; i++) {
                    process_1 = processToRun[i];
                    process_1(frame);
                    if (toKeepAlive.has(process_1) === true && !cancelled.has(process_1)) {
                        renderStep.schedule(process_1);
                        setRunNextFrame(true);
                    }
                }
            }
            isProcessing = false;
        },
        schedule: (process, keepAlive, immediate) => {
            if (keepAlive === void 0) { keepAlive = false; }
            if (immediate === void 0) { immediate = false; }
            invariant(typeof process === 'function', 'Argument must be a function');
            let addToCurrentBuffer = immediate && isProcessing;
            let buffer = addToCurrentBuffer ? processToRun : processToRunNextFrame;
            cancelled.delete(process);
            if (keepAlive)
                toKeepAlive.add(process);
            if (buffer.indexOf(process) === -1) {
                buffer.push(process);
                if (addToCurrentBuffer)
                    numThisFrame = processToRun.length;
            }
        }
    };
    return renderStep;
});

let StepId;
((StepId) => {
    StepId['Read'] = 'read';
    StepId['Update'] = 'update';
    StepId['Render'] = 'render';
    StepId['Cleanup'] = 'cleanup';
    StepId['FixedUpdate'] = 'fixedUpdate';
})(StepId || (StepId = {}));

let maxElapsed = 40;
let defaultElapsed = (1 / 60) * 1000;
let useDefaultElapsed = true;
let willRunNextFrame = false;
let isProcessing = false;
let frame = {
    delta: 0,
    timestamp: 0
};
let stepsOrder = [
    StepId.Read,
    StepId.Update,
    StepId.Render,
    StepId.Cleanup
];
let setWillRunNextFrame = (willRun) => { return (willRunNextFrame = willRun); };
let _a = stepsOrder.reduce((acc, key) => {
    let step = createStep(setWillRunNextFrame);
    acc.sync[key] = (process, keepAlive, immediate) => {
        if (keepAlive === void 0) { keepAlive = false; }
        if (immediate === void 0) { immediate = false; }
        if (!willRunNextFrame)
            startLoop();
        step.schedule(process, keepAlive, immediate);
        return process;
    };
    acc.cancelSync[key] = (process) => { return step.cancel(process); };
    acc.steps[key] = step;
    return acc;
}, {
    steps: {},
    sync: {},
    cancelSync: {}
}), steps = _a.steps, sync = _a.sync, cancelSync = _a.cancelSync;
let processStep = (stepId) => { return steps[stepId].process(frame); };
let processFrame = (timestamp) => {
    willRunNextFrame = false;
    frame.delta = useDefaultElapsed
        ? defaultElapsed
        : Math.max(Math.min(timestamp - frame.timestamp, maxElapsed), 1);
    if (!useDefaultElapsed)
        defaultElapsed = frame.delta;
    frame.timestamp = timestamp;
    isProcessing = true;
    stepsOrder.forEach(processStep);
    isProcessing = false;
    if (willRunNextFrame) {
        useDefaultElapsed = false;
        onNextFrame(processFrame);
    }
};
let startLoop = () => {
    willRunNextFrame = true;
    useDefaultElapsed = true;
    if (!isProcessing)
        onNextFrame(processFrame);
};
let getFrameData = () => { return frame; };
attach(sync, { cancelSync, getFrameData });


module.exports = sync;
