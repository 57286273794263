'use strict';

const mods = ({
    vList: ({
        oninit: ({state}) => (state.conf = style({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '18px',
            padding: '4px',
            height: '100%',
            width: 'fit-content',
            margin: 'auto'
        })),
        view: ({
            attrs, state: {conf}, children
        }) => m(`div.list.relative[uid="${sid()}"]`, stack(conf, attrs), children)
    }),
    hList: ({
        oninit: ({state}) => (state.conf = style({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            padding: '4px 8px',
            width: '100%',
            height: 'fit-content',
            margin: 'auto'
        })),
        view: ({
            attrs, state: {conf}, children
        }) => m(`div.list.relative[uid="${sid()}"]`, stack(conf, attrs), children)
    }),
    item: ({
        oninit: ({state}) => (state.conf = style({
            borderRadius: '0 12px',
            padding: '14px 18px'
        })),
        view: ({
            attrs, state: {conf}, children
        }) => m(`span.item.relative[uid="${sid()}"]`, stack(conf, attrs), children)
    })
});

let fns = {};
[ 'vList', 'hList', 'item' ].forEach(
    (n) => (fns[n] = (...v) => m(mods[n], ...v))
);

module.exports = fns;
