'use strict';


//const spawn = require('child_process').spawn;
let   tmr   = ({});
const tickr = (id) => {
    tmr[id].nx += tmr[id].ms;
    tmr[id].tmt = wait(
        () => {
            tickr(id);
            tmr[id].fn();
        },
        Math.max( tmr[id].nx - time(), 0 )
    );
    return true;
};
let __ = ({
    is:  vfn('...'),
    step: {boot: [], live: [], exit: []}
});
const daemon = build({
    on:   build({
        boot: fn => __.is() !== '...' ? __.step.boot.push(fn) : next(fn),
        live: fn => __.is() !== 'live' ? __.step.live.push(fn) : next(fn),
        exit: fn => __.is() !== 'exit' ? __.step.exit.push(fn) : next(fn)
    }),
    feed: __.is.echo,
    init: () => {
        if (__.is() !== '...') { return; }
        __.is('boot');
        __.step.boot.forEach(_);
        __.step.boot = [];
        next(() => {
            __.is('live');
            __.step.live.forEach(_);
            __.step.live = [];
            keys(tmr).forEach(id => (
                tmr[id].nx = time() + tmr[id].dl,
                tickr(id)
            ));
        });
    },
    tick: (id, fn, ms = 1000, dl = 0) => {
        if (__.is() === 'exit') { return; }
        tmr[id] && tmr[id].tmt && nope(tmr[id].tmt);
        if (isFunction(fn)) {
            tmr[id] = ({ms, dl, fn});
            if (__.is() === 'live') {
                tmr[id].nx = time() + tmr[id].dl,
                tickr(id)
            }
        } else {
            delete tmr[id];
        }
    },
    /*brat: (name, pr = 0) => __.is() !== 'exit' && _((
        I = {am: vfn(), proc: null},
        exit = () => {
            !defined(I.proc.exitCode) && I.proc.stdin.end();
            I.am('dead');
            exit = fnVoid;
        }
    ) => build({
        feed: I.am.echo,
        init: () => {
            if (__.is() === 'exit' || I.am()) { return; }
            I.am('starting');
            I.proc = spawn('./brat', [name]);
            I.proc.stderr.pipe(process.stderr, {end: false});
            I.proc.on('error', (err) => {
                process.stderr.write(err);
                defined(I.proc.exitCode) && next(() => I.am('dead'));
            });
            I.proc.on('close', () => I.am('dead'));
            os.setPriority(I.proc.pid, prior(pr));
            I.am('running');
            __.step.exit.push( exit );
        },
        kill: () => {
            exit();
            exit = fnVoid;
        },
        code: () => I.proc.exitCode
    })),*/
    kill: () => {
        if (__.is() !== 'live') { return; }
        __.is('exit');
        keys(tmr).forEach(
            (id) => {
                tmr[id].tmt && nope(tmr[id].tmt);
                delete tmr[id];
            }
        );
        tmr = null;
        __.step.exit.forEach(_);
        __.step.exit = [];
        __.is('dead');
        wait(process.exit, 500).unref();
    }
});


module.exports = ({ daemon });
