'use strict';

import {morph, ease} from './gsap';



class Cursor {
    init() {
        this.initCursor();
        this.initHovers();
    }

    initCursor() {
        const { Back } = ease;
        this.outerCursor = document.querySelector('[cursing][hollow]');
        this.innerCursor = document.querySelector('[cursing][point]');
        this.outerCursorBox = this.outerCursor.getBoundingClientRect();
        this.outerCursorOriginals = {
            width: 30,
            height: 30,
            borderRadius: '50%'
        };
        this.outerCursorSpeed = 0;
        this.easing = Back.easeOut;
        this.clientX = -100;
        this.clientY = -100;
        this.active = false;

        this.unveilCursor = () => {
            morph.set(this.innerCursor, {
                x: this.clientX,
                y: this.clientY
            });
            morph.set(this.outerCursor, {
                x: this.clientX - 15,
                y: this.clientY - 15
            });
            setTimeout(() => {
                this.outerCursorSpeed = 0.2;
            }, 100);
            this.active = true;
        };
        document.addEventListener('mousemove', this.unveilCursor);
        document.addEventListener('mousemove', e => {
            this.clientX = e.clientX;
            this.clientY = e.clientY;
        });

        const render = () => {
            morph.set(this.innerCursor, {
                x: this.clientX,
                y: this.clientY
            });
            if (!this.isStuck) {
                morph.to(this.outerCursor, this.outerCursorSpeed, {
                    x: this.clientX - 15,
                    y: this.clientY - 15
                });
            }
            if (this.active) {
                document.removeEventListener('mousemove', this.unveilCursor);
            }
            requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
        window.addEventListener('blur', () => next(() => this.hideCursor()));
        window.addEventListener('focus', () => next(() => this.showCursor()));
    }

    initHovers() {
        const linkHoverTween = morph.to(this.innerCursor, 0.2, {
            backgroundColor: 'rgba(255, 150, 150, 0.45)',
            scale: '3.5',
            paused: true
        });
        let squarish = ({width, height}) => width > 10 && height > 10
            ? Math.round(width / 10) === Math.round(height / 10)
            : true;
        this.handleLink = {
            MouseEnter: target => {
                this.isStuck = true;
                const box = target.getBoundingClientRect();
                const rounded = squarish(box);
                const rounding = rounded ? '50%' : '8px';
                const borderWidth = '1px';
                morph.to(this.outerCursor, 0.2, {
                    x: box.left,
                    y: box.top,
                    width: box.width,
                    height: box.height,
                    borderColor: 'var(--cursor-link)',
                    borderWidth: borderWidth,
                    borderRadius: rounding
                });
                //linkHoverTween.play();
                morph.to(this.innerCursor, 0.4, {
                    filter: 'opacity(0)'
                });
            },
            MouseLeave: () => {
                this.isStuck = false;
                morph.to(this.outerCursor, 0.2, {
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    borderWidth: '2px',
                    borderColor: 'rgba(255, 100, 100, 0.5)'
                });
                //linkHoverTween.reverse();
                morph.to(this.innerCursor, 0.4, {
                    filter: 'opacity(1)'
                });
            }
        };
        const mainNavHoverTween = morph.to(this.outerCursor, 0.3, {
            filter: 'opacity(0)',
            scale: '1',
            paused: true
        });

        this.handleNav = {
            MouseEnter: target => {
                this.outerCursorSpeed = 0;
                morph.set(this.innerCursor, { opacity: 0 });
                mainNavHoverTween.play();
            },
            MouseLeave: target => {
                this.outerCursorSpeed = 0.2;
                morph.set(this.innerCursor, { opacity: 1 });
                mainNavHoverTween.reverse();
            }
        };
        const textHoverTween = morph.to(this.innerCursor, 0.2, {
            backgroundColor: 'rgba(255, 100, 100, 0.8)',
            width: '5px',
            height: '5px',
            paused: true
        });
        this.handleText = {
            MouseEnter: target => {
                this.isStuck = true;
                const box = target.getBoundingClientRect();
                const rounding = '0 8px';
                const borderWidth = '1.5px';
                morph.to(this.outerCursor, 0.2, {
                    x: box.left,
                    y: box.top,
                    width: box.width,
                    height: box.height,
                    borderColor: 'rgba(255, 100, 100, 0.6)',
                    borderWidth: borderWidth,
                    borderRadius: rounding
                });
                morph.to(this.innerCursor, 0.4, {
                    filter: 'opacity(0)'
                });
                textHoverTween.play();
                //next( () => target.focus() );
            },
            MouseLeave: () => {
                this.isStuck = false;
                morph.to(this.innerCursor, 0.4, {
                    filter: 'opacity(1)'
                });
                morph.to(this.outerCursor, 0.2, {
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    borderWidth: '2px',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(255, 100, 100, 0.5)'
                });
                textHoverTween.reverse();
            }
        };
    }

    showCursor() {
        this.isStuck = false;
        morph.set(this.innerCursor, {
            x: this.clientX,
            y: this.clientY
        });
        morph.set(this.outerCursor, {
            x: this.clientX - 15,
            y: this.clientY - 15
        });
        setTimeout(() => {
            this.outerCursorSpeed = 0.2;
        }, 100);
        morph.to(this.innerCursor, 0.2, {
            filter: 'opacity(1)'
        });
        morph.to(this.outerCursor, 0.2, {
            filter: 'opacity(1)'
        });
    }

    hideCursor() {
        this.isStuck = false;
        morph.to(this.innerCursor, 0.2, {
            filter: 'opacity(0)'
        });
        morph.to(this.outerCursor, 0.2, {
            filter: 'opacity(0)'
        });
    }

    initLink(item) {
        item.onmouseenter = () => this.handleLink.MouseEnter(item);
        item.onmouseleave = () => this.handleLink.MouseLeave(item);
    }

    dropLink(item) {
        item.onmouseenter = null;
        item.onmouseleave = null;
    }

    initNav(item) {
        item.onmouseenter = () => this.handleNav.MouseEnter(item);
        item.onmouseleave = () => this.handleNav.MouseLeave(item);
        item.onmouseup    = () => this.handleNav.MouseLeave(item);
    }

    dropNav(item) {
        item.onmouseenter = null;
        item.onmouseleave = null;
    }

    initText(item) {
        item.onmouseenter = () => this.handleText.MouseEnter(item);
        item.onmouseleave = () => this.handleText.MouseLeave(item);
        item.onblur = () => this.handleText.MouseLeave(item);
    }

    dropText(item) {
        item.onmouseenter = null;
        item.onmouseleave = null;
    }
}


module.exports = Cursor;
