'use strict';

import { image, bgImageStyle } from './image';

const br = () => m('br');
const hr = () => m('hr');
let fns = {
    tag, br, hr, image, bgImageStyle
};
const tag   = (t) => (...v) => m(`${t}`, ...v );
const tagme = (t, a = t) => ( fns[a] = tag(t) );
[
    ['i.material-icons', 'ico'],
    ['button', 'btn'],
    ['a.link', 'link'],
    ['div.iblock', 'block'],
    ['span.txt-5', 't5'],
    ['span.txt-4', 't4'],
    ['span.txt-3', 't3'],
    ['span.txt-3', 't'],
    ['span.txt-2', 't2'],
    ['blockquote', 'quote']
].forEach( v => tagme(...v) );
[
    'header', 'nav', 'aside', 'main', 'article', 'section', 'footer',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'p',
    'canvas', 'div', 'span', 'form', 'button', 'input', 'textarea'
].forEach( v => tagme(v) );

module.exports = fns;
