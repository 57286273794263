'use strict';


const pipe = (v, ...fns) => fns.reduce(
    (acc, fn) => (acc = fn(acc)), v
);
const apply = (v, fn) => ( fn(v), v );
const chain = (v, ...fns) => fns.forEach( fn => fn(v) );
const clamp = (v, min, max) => (i) => Math.min(Math.max(i, min), max);
const proto = (v) => ![undefined, null].includes(v)
    ? (v?.constructor?.name) ?? `${v}`
    : `${v}`;


equip: {
    let equip = (pack) => (target, props) => (
        Object.defineProperties(target, (
            Object.entries(props).map(
                ([k, v]) => (props[k] = ({
                    ...pack(target, v), enumerable: true
                }))
            ),
            props
        )),
        target
    );
    let props = equip( (t, v) => ({value: v}) );
    let build = (v) => props({}, v);
    let weld  = equip( (t, fn) => ({value: fn(t)}) );
    let make  = (v) => weld({}, v);
    let api   = equip( (t, v) => ({...v}) );
    let lib   = equip( (t, iofns) => (
        {...Object.entries(iofns).map(
            ([k, fn]) => [k, fn(t)]
        )}
    ) );
    let attach = props;
    let env    = (o) => props(global, o);
    env({
        pipe, apply, chain, clamp, proto,
        attach, props, build, weld, make,
        api, lib, env
    });
}

libraries: {
    env({
        m: require('./mithril'),
        sync: require('./sync')
    });
}

robbery: {
    let {log, warn, debug, error, trace} = console;
    let {assign, keys, values, entries, fromEntries} = Object;
    let next = (fn) => setTimeout(fn, 0);
    let wait = (fn, ms) => setTimeout(fn, ms);
    let nope = (tmr) => clearTimeout(tmr);
    let time = () => Date.now();
    let tick = (fn, ms, nxt = time() + 10, unref = false) => {
        let tmr, tck = () => {
                tmr = wait( tck, Math.max(nxt - time(), 0) );
                nxt += ms;
                fn();
            };
        tck();
        return build({
            next: () => nxt,
            stop: () => tmr && (
                nxt = 0, nope(tmr)
            ),
            tick: (nxt = time() + 10) => !tmr && tck()
        });
    };
    let defer = (fn) => wait(fn, 2);
    let timer = (label = 'duration') => _(
        (s = nano()) => (all) => _(
            (ms = (Number(nano() - s) / 1000 | 0) / 1000) => (
                all ? [ms, `${label}: ${ms}ms`] : `${label}: ${ms}ms`
            )
        )
    );
    let fncb   = (fn, cb) => () => {
        let r = fn();
        !!r && r.then ? r.then(cb) : cb();
    };
    let method = (...fns) => {
        fns = fns.reverse();
        let pfn = () => fns.length && next( fncb(fns.pop(), pfn) );
        pfn();
    };
    env({
        log, warn, debug, error, trace,
        assign, keys, values, entries, fromEntries,
        next, wait, nope, tick, time, defer, timer,
        fncb, method
    });
}

composition: {
    let _ = (v) => (
        ![undefined, null].includes(v)
        && v?.constructor?.name === 'Function'
    ) ? v() : v;
    let maybe = (fn, dfn = (v) => v, efn = error) => {
        try  { return dfn( fn() ); }  catch (e) { efn( e ); }
    };
    let ab_ab = (fn) => (a) => (b) => fn(a, b);
    let ba_ab = (fn) => (b) => (a) => fn(a, b);
    let abb_abb = (fn) => (a) => (...b) => fn(a, ...b);
    let bba_abb = (fn) => (...b) => (a) => fn(a, ...b);
    let fns = ['apply', 'clamp', 'attach', 'weld', 'api', 'lib'];
    env({
        _, maybe, ab_ab, ba_ab,
        ...fromEntries(fns.map( (name) => [`_${name}`, ab_ab(global[name])] )),
        ...fromEntries(fns.map( (name) => [`${name}_`, ba_ab(global[name])] ))
    });
    fns = ['pipe', 'chain'];
    env({
        abb_abb, bba_abb,
        ...fromEntries(fns.map( (name) => [`_${name}`, abb_abb(global[name])] )),
        ...fromEntries(fns.map( (name) => [`${name}_`, bba_abb(global[name])] ))
    });
}

utility: {
    let random = (min, max = 0) => (
            min < max || ([min, max] = [max, min]),
            Math.floor(Math.random() * (max - min + 1)) + min
        ),
        range  = (start, end) => new Array(end - start + 1)
            .fill(undefined)
            .map((_, i) => i + start),
        shuffle = (arr) => arr.slice().map(
            () => arr[lib.random(arr.length) - 1]
        ),
        stagger = (ms, fn, vals, cb) => {
            let q = vals.slice(),
                t = setInterval(() => {
                    (fn(q.shift()) || true) &&
                    !(q.length) && (clearInterval(t) || true) &&
                    cb && cb()
                }, ms);
            return () => (t && (clearInterval(t) || true) && cb && cb());
        };
    let strPad = (val = '', lng = 2, chr = '0') => `${val}`.padStart(lng, chr),
        pad    = (i = '') => `${i}`.padStart(2, '0');
    env({
        random, range, shuffle, stagger,
        strPad, pad
    });
}

toolset: {
    env({
        defined:     (v) => ![undefined, null].includes(v),
        router:      (map, dfltRte) => ([r, ...v]) => r in map ? map[r](...v) : dfltRte(...v),
        casing:      (map, dfltVal) => (v) => v in map ? map[v] : dfltVal,
        nullish:     (v) => [undefined, null].includes(v),
        union:       (a, b) => [...( new Set([...a, ...b]) )],
        overlap:     (a, b) => [
            ...a.filter( v => b.includes(v) )
        ],
        differ:      (a, b) => [
            ...a.filter( v => !b.includes(v) ),
            ...b.filter( v => !a.includes(v) )
        ],
        // Predicate
        isFunction:  (v) => proto(v) === 'Function',
        isAsyncFn :  (v) => proto(v) === 'AsyncFunction',
        isObject:    (v) => proto(v) === 'Object',
        isArray:     (v) => proto(v) === 'Array',
        isMap:       (v) => ['Map', 'WeakMap'].includes( proto(v) ),
        isSet:       (v) => ['Set', 'WeakSet'].includes( proto(v) ),
        isPromise:   (v) => proto(v) === 'Promise',
        isDate:      (v) => proto(v) === 'Date',
        isNumber:    (v) => proto(v) === 'Number' && !isNaN(v),
        BigInt:      (v) => proto(v) === 'BigInt',
        isBoolean:   (v) => proto(v) === 'Boolean',
        isString:    (v) => proto(v) === 'String',
        // Functional
        fnOops:      (e) => void( e && console.error(e) ),
        fnVoid:      ( ) => {},
        fnNull:      ( ) => null,
        fnEcho:      (v) => () => v,
        fnBool:      (v) => !!v
    });
}

browser: {
    let gent  = require('@egjs/agent').default();
    let media = {
        browser:   gent.browser.name,
        isMobile:  gent.isMobile || gent.browser.webview,
        isBrowser: !gent.isMobile && !gent.browser.webview,
        sclFactor: gent.isMobile ? 0.5 : 1,
        density:   window.devicePixelRatio
    };
    let param = (spec) => ({spec});
    let stack = (...args) => {
        let t = {};
        args.filter( isObject ).forEach(
            o => entries(o).forEach(
                ([k, v]) => isObject(t[k]) && isObject(v)
                    ? (t[k] = stack(t[k], v))
                    : (t[k] = v)
            )
        );
        return t;
    };
    let style = (...args) => {
        let t = { class: [], style: {} };
        args.filter( isString ).map(
            (s) => '[]' === s.slice(0, 1) + s.slice(-1)
                ? s.split('=').map(si => si.replace('"', ''))
                : ['class', s.replace('.', '')]
        ).forEach(
            ([k, v]) => k === 'class'
                ? ( t.class.push(v) )
                : ( t[k] = v ?? true )
        );
        args.filter( isObject ).forEach(
            (o) => entries(o).forEach(
                ([k, v]) => isObject(t.style[k]) && isObject(v)
                    ? (t.style[k] = stack(t.style[k], v))
                    : (t.style[k] = v)
            )
        );
        if (t.class.length) {
            t.class = t.class.join(' ');
        } else {
            delete t.class;
        }
        return t;
    };
    let config = (obj = {}) => ({config: obj});
    let anchor = {
        center:       () => style({transformOrigin: 'center'}),
        topLeft:      () => style({transformOrigin: 'top left'}),
        topCenter:    () => style({transformOrigin: 'top center'}),
        topRight:     () => style({transformOrigin: 'top right'}),
        centerRight:  () => style({transformOrigin: 'center right'}),
        bottomRight:  () => style({transformOrigin: 'bottom right'}),
        bottomCenter: () => style({transformOrigin: 'bottom center'}),
        bottomLeft:   () => style({transformOrigin: 'bottom left'}),
        centerLeft:   () => style({transformOrigin: 'center left'})
    };
    let font  = {
        weight: (v) => style({fontWeight: v}),
        size:   (v) => style({fontSize: `calc(${v}px + .5vmax)`}),
        height: (v) => style({lineHeight: `${v}em`}),
        caps:   ( ) => style({fontVariant: 'small-caps'}),
    };
    env({
        media, param, stack, style, config, font
    });
}

masking: {
    const masker = (el, mask, value = vfn('')) => {
        let s = 0;
        const d = {9: '[0-9]', a: '[a-z]'}, msk = mask.split('');
        const ss = msk.findIndex(ch => d[ch]);
		const handler = (e) => {
            if (e.type === 'focus' && el.value !== '') return;
            let mskd = [], move = ![
                'deleteContentBackward', 'deleteContentForward'
            ].includes(e.inputType);
            s = Math.max(ss, el.selectionStart) - (move ? 1 : 0);
			msk.forEach((ch, n) => {
				if (d[ch]) {
					let t = new RegExp(d[ch], 'i').test(el.value.charAt(n));
					mskd.push(t ? el.value.charAt(n) : '_');
					if (move && t && s === n && el.value.charAt(n) !== '_') {
						s++;
					}
				} else {
					mskd.push(ch);
					if (move && s === n) s++;
				}
            });
            el.value = value( mskd.join('') );
            el.selectionStart = el.selectionEnd = Math.max(0, s);
        }
        const mover = (e) => {
            let si = el.selectionStart - 1;
            if (e.key === 'Backspace' && !d[msk[si]]) {
                el.selectionStart = el.selectionEnd = Math.max(ss, si);
            }
        };
        const bouncer = (e) => {
            if (el.selectionStart < ss) {
                el.selectionStart = el.selectionEnd = ss;
            }
        };
		el.addEventListener('input', handler);
        el.addEventListener('focus', handler);
        el.addEventListener('keydown', mover);
        el.addEventListener('keyup', bouncer);
    };
    env({ masker });
}

equate: {
    let cased = casing({
        Array:  (a, b) => (
            a.length === b.length
            && a.every( (v, i) => equal( a[i], b[i] ) )
        ),
        Set:    (a, b) => [...a].every( v => b.has(v) ),
        Object: (a, b) => (
            !differ(keys(a), keys(b)).length
            && keys(a).every(k => equal(a[k], b[k]))
        ),
        Map:    (a, b) => [...a.keys()].every(
            k => b.has(k) && equal(a.get(k), b.get(k))
        )
    }, (a, b) => String(a) === String(b));
    let equal = (value, other) => {
        if (proto(value) !== proto(other)) {
            return false;
        }
        if (
            ![value, other].every(defined)
            || [value, other].every(v => typeof v !== 'object')
        ) {
            return value === other;
        }
        return cased( proto(value) )( value, other );
    }
    env({ equal });
}

json: {
    let jsn = build({
        parse:  (v) => JSON.parse(v),
        string: (v) => JSON.stringify(v),
        clean:  (v) => JSON.parse( JSON.stringify(v) ),
        pretty: (v, ws = 4) => JSON.stringify(v, undefined, ws)
    });
    env({ jsn });
}

hollow: {
    let cased = casing({
        String: (v) => !v.length,
        Array:  (v) => !v.length,
        Set:    (v) => !v.size,
        Object: (v) => !keys(v).length,
        Map:    (v) => !v.size
    }, (v) => !v);
    let empty = (v) => !defined(v) || cased( proto(v) )( v );
    env({ empty });
}

framing: {
    let frameSync = (fn, step) => {
        let q = [];
        return (v) => (
            !q.length && step(() => fn( q.pop() )),
            q = [v]
        );
    };
    const framed = ({
        read    : (fn) => frameSync(fn, sync.read),
        update  : (fn) => frameSync(fn, sync.update),
        render  : (fn) => frameSync(fn, sync.render),
        cleanup : (fn) => frameSync(fn, sync.cleanup)
    });
    env({ framed });
}

clockwork: {
    /* (tokenizer, tokenMap) => clockwork */
    /* (...sequence) => clockwork => (result) */
    const tumbler  = (i, d) => isFunction(d) ? d(i) : d;
    const phrased  = (...fns) => {
        fns = fns.reverse();
        let pfn = (v) => {
            let fn = fns.pop();
            fns.length || (pfn = fn);
            return fn(v);
        };
        return pfn;
    };
    const machine   =  (feeder) => (clock, coin) => (
        isObject(clock) ? tumbler( coin, clock[ feeder(coin) ] ) ?? clock : clock
    );
    const clockwork = (cwFeeder, cwDevice) => _(
        ( cwGolem = machine(cwFeeder) ) => (
            (...coins) => coins.reduce(
                cwGolem, cwDevice
            )
        )
    );
    clockwork.phrased = phrased;
    env({ clockwork });
}

functional: {
    let linkage = ($, io, lfn) => (
        $.fns.push( lfn ), (
            !io.detach && !io.source ? weld(io, {
                source: (__) => vfn([[$, lfn]]),
                detach: (__) => () => {
                    __.source().forEach(
                        ([{fns}, lfn]) => {
                            let idx = fns.indexOf(lfn);
                            idx !== -1 && fns.splice(idx, 1)
                        }
                    );
                    __.source([]);
                }
            }) : io.source().push([$, lfn]),
            defined($.v) && lfn($.v),
            io
        )
    );
    let vfn = (v) => _((
        $ = {fns: [], v}
    ) => props( pipe_(
        (v) => (defined(v) && v !== $.v && ($.v = v, $.fns.length && chain($.v, ...$.fns)), $.v)
    ), {
        toJSON: () => $.v ?? null,
        json:   () => jsn.string($.v ?? ''),
        map:    (fn, io = vfn()) =>     linkage( $, io, v => io( fn(v) ) ),
        filter: (fn, io = vfn()) =>     linkage( $, io, v => fn(v) && io(v) ),
        feed:   (io = vfn()) =>         linkage( $, io, v => io(v) ),
        echo:   (fn) =>                 linkage( $, fn, v => fn(v) ),
        into:   (obj, key, cnt = {}) => linkage(
            $, cnt, isMap(obj) ? v => obj.set(key, v) : v => (obj[key] = v)
        )
    } ));
    let rfn = (v) => _((
        $ = {fns: [], v}
    ) => props( pipe_(
        (v) => (defined(v) && ($.v = v, $.fns.length && chain($.v, ...$.fns)), $.v)
    ), {
        toJSON: () => $.v ?? null,
        json:   () => jsn.string($.v ?? ''),
        map:    (fn, io = vfn()) =>     linkage( $, io, v => io( fn(v) ) ),
        filter: (fn, io = vfn()) =>     linkage( $, io, v => fn(v) && io(v) ),
        feed:   (io = vfn()) =>         linkage( $, io, v => io(v) ),
        echo:   (fn) =>                 linkage( $, fn, v => fn(v) ),
        into:   (obj, key, cnt = {}) => linkage(
            $, cnt, isMap(obj) ? v => obj.set(key, v) : v => (obj[key] = v)
        )
    } ));
    let ifn = ([min, max], cfn = fnVoid, ival) => _((
        $ = {fns: [], v: ival ?? 1 * min, min, max: max + 1, cfn}
    ) => props(() => $.v, {
        json:  () => jsn.string($.v),
        tick:  () => {
            ++$.v === $.max && ($.v = 1 * $.min, cfn());
            $.fns.length && chain($.v, ...$.fns);
        },
        value: () => $.v,
        count: (i = 5, io = vfn()) => {
            let on = range(0, (max - min) / i | 0).map( ii => ii * i );
            return linkage($, io, v => on.includes(v) && io(v));
        },
        echo:  (fn) => linkage( $, fn, v => fn(v) ),
        into:  (obj, key, cnt = {}) => linkage(
            $, cnt, isMap(obj) ? v => obj.set(key, v) : v => (obj[key] = v)
        )
    } ));
    env({ vfn, rfn, ifn });
}

daemonic: {
    env( require('./crypto-id') );
    env( require('./state') );
    env( require('./clock') );
    env( require('./compare') );
    env( require('./skema') );
    env( require('./daemon') );
    env( require('./websocket') );
}
